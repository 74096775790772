export default {
  white: '#ffffff',
  black100: '#f6f6f6',
  black200: '#ebebeb',
  black300: '#d7d7d7',
  black400: '#cacaca',
  black500: '#b9b9b9',
  black600: '#9b9b9b',
  black700: '#878787',
  black800: '#696969',
  black900: '#4b4b4b',
  black1000: '#111111',
  black: '#111111',
  transparent: 'rgba(0, 0, 0, 0)',

  // Colorful colors

  blue200: '#d4f9ff',
  blue400: '#b1eefc',
  blue600: '#63daff',
  blue800: '#0a85ea',
  blue900: '#0977d1',

  green200: '#d0fce4',
  green400: '#abffdb',
  green600: '#5befbd',
  green800: '#23d09c',
  green900: '#2ab589',

  yellow200: '#fefbb8',
  yellow400: '#fbf1a0',
  yellow600: '#fce76b',
  yellow800: '#f2d211',
  yellow900: '#d9bc2d',

  red200: '#ffd7dc',
  red400: '#ffaab9',
  red600: '#ff7885',
  red800: '#f44e66',
  red900: '#d44648',

  purple200: '#e1e1ff',
  purple400: '#c6befc',
  purple600: '#9986f7',
  purple800: '#7646ec',
  purple900: '#6944ce',
};
